import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CMSWrapper from '@ic-hosting/ich-contentful-cms-wrapper';
import { updateData } from '../data/contentful/actions';
import Layout from '../components/layout';
import SectionNews from '../components/IndexPage/SectionNews';

//import CMSWrapper, { ImageEditor, TypeEditor } from'../local-modules/@ic-hosting/ich-contentful-cms-wrapper/src';

class LocationTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = props.state;
  }

  componentWillMount() {

  }

  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.updateData();
  }

  render() {
    const {
      // data,
      pageContext,
      location,
      content,
    } = this.props;
    // const post = data.markdownRemark;
    const { slug } = pageContext;
    try {

    
    const newsData = content.data.news[slug];
    const allNews = content.data.news.all;
    const allEvents = content.data.event.all;
    const allBlogs = content.data.blogs.all;
    const placeholder = content.data.assets.Placeholder;
    return (
      <Layout location={location} title={newsData.title}>
        <Helmet>
          <title>{newsData.title}</title>
        </Helmet>
        <section className="inner-header divider parallax layer-overlay overlay-dark-5" data-bg-img={
          newsData.image ? newsData.image.size({
            width: 1500
          })
        : null }
        >
          <CMSWrapper {...newsData}>
          <div className="container pt-100 pb-50">
            <div className="section-content pt-100">
              <div className="row"> 
                <div className="col-md-12">
                  <h3 className="title text-white"><span className="text-theme-colored">News: </span>{newsData.title}</h3>
                </div>
              </div>
            </div>
          </div>
          </CMSWrapper>
        </section>
        <section>
          <div className="container pb-30">
            <div className="section-content">
              <div className="row">
                <div className="col-md-5 pr-60 pr-sm-0 mt-sm-60">
                  <div className="widget">
                    <h4 className="widget-title line-bottom text-uppercase bg-theme-colored p-10 text-white">News Details</h4>
                    <div className="opening-hours">
                      <ul className="list-border">
                        <li className="clearfix"> <span> Created:  </span>
                          <div className="value pull-right flip"> {new Date(newsData.createdAt).toLocaleDateString()}</div>
                        </li>
                        <li className="clearfix"> <span> Date:</span>
                        <div className="value pull-right flip"> {newsData.date ? new Date(newsData.date).toLocaleDateString() : null}</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="single-class-details">
                    <img src={
                      newsData.image ? newsData.image.size({
                        width: 650,
                        height: 440
                      })
                    : placeholder.size({
                      width: 650,
                      height: 440
                    })
                    } className="img-fullwidth" alt="" />
                    <h5>Information</h5>
                    <div dangerouslySetInnerHTML={{__html: newsData.details}} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <SectionNews placeholder={placeholder} news={allNews} events={allEvents} blogs={allBlogs} />
      </Layout>
    );
    } catch (e) {
      return null;
    }
  };
}

const mapStateToProps = state => ({ state, content: state.contentful });

const mapDispatchToProps = dispatch => ({
  updateData: bindActionCreators(updateData, dispatch),
});



export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationTemplate);
